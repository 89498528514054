
export const adminToolbarIcons: Record<string, string> = {
  "fallback": "/themes/contrib/gin/dist/media/sprite.svg#fallback-view",
  "system.admin_content": "/themes/contrib/gin/dist/media/sprite.svg#content-view",
  "system.admin_structure": "/themes/contrib/gin/dist/media/sprite.svg#structure-view",
  "system.themes_page": "/themes/contrib/gin/dist/media/sprite.svg#appearance-view",
  "system.modules_list": "/themes/contrib/gin/dist/media/sprite.svg#extend-view",
  "system.admin_config": "/themes/contrib/gin/dist/media/sprite.svg#config-view",
  "entity.group.collection": "/themes/contrib/gin/dist/media/sprite.svg#group-view",
  "entity.user.collection": "/themes/contrib/gin/dist/media/sprite.svg#people-view",
  "system.admin_reports": "/themes/contrib/gin/dist/media/sprite.svg#reports-view",
  "help.main": "/themes/contrib/gin/dist/media/sprite.svg#help-view",
  "commerce.admin_commerce": "/themes/contrib/gin/dist/media/sprite.svg#commerce-view",
  "<front>": "/themes/contrib/gin/dist/media/sprite.svg#gin-view",
  "tmgmt.admin_tmgmt": "/themes/contrib/gin/dist/media/sprite.svg#tmgmt-view"
}
